<template>
  <div>
    <div class="flex">
      <p class="font-barlow font-medium text-xl text-grey-medium-2">
        {{ title }} {{ displayVersionForSeller(productVersion) }} /
      </p>
      <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
        Settings
      </p>
      <div class="flex flex-grow justify-end">
        <Button
          text="Back"
          type="primary-white"
          size="medium"
          :iconLeft="arrowLeft"
          :rotateArrow="true"
          :onClick="goBack"
          class="mr-2"
          data-testid="back-button"
        />
        <Button
          text="Next"
          type="primary"
          :iconRight="arrowRight"
          size="medium"
          :onClick="goNext"
          data-testid="next-button"
        />
      </div>
    </div>

    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
        Define Timeline and Permissions
      </p>
      <p class="text-mdh text-grey-dark-1 mb-3 max-w-1/2">
        The dates and settings below are recommendations for this assessment.
        You can make changes where you need to.
      </p>
      <div class="px-2">
        <p class="font-bold text-mdlh text-grey-dark-1 mb-1">
          Assessment Duration
        </p>
        <p class="text-mdh text-grey-dark-1 mb-3 max-w-1/2">
          Set a launch date and close date for the assessment. Participants will
          receive invitations to the assessment on the date you select in the
          Communication setup.
        </p>

        <div class="mb-6 flex items-end">
          <div class="flex justify-center items-center">
            <Select
              label="Timezone"
              v-model="timezone"
              :options="timezoneOptions"
              :preselectFirst="false"
              :onSelect="(value) => (timezone = value)"
              class="mr-2"
              disabled
            />
            <DatePicker
              v-model="range.start"
              :key="(range.start + Math.random())"
              mode="dateTime"
              :min-date="mode !== 'set-up' ? undefined : new Date()"
              :max-date="range.end"
              timezone="UTC"
              :is-required="true"
              data-testid="start-date-select"
              :popover="launchDatePopover"
              :model-config="{
                type: 'string',
                mask: 'YYYY-MM-DDTHH:mm:ss',
                timeAdjust: '06:00:00',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div>
                  <p
                    class="text-sm-2 text-grey-light font-medium mb-0.4 relative"
                  >
                    Launch Date
                  </p>
                  <div class="flex">
                    <input
                      :value="inputValue"
                      :disabled="disabledLaunchDate"
                      v-on="inputEvents"
                      class="w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
                    />
                    <img
                      src="../../../assets/img/icons/date.svg"
                      class="relative right-4"
                    />
                  </div>
                </div>
              </template>
              <template v-slot:footer>
                <div class="absolute bottom-0 inset-x-0 h-68 opacity-60 bg-white rounded-b-lg" />
              </template>
            </DatePicker>
            <DatePicker
              v-model="range.end"
              :key="(range.end + Math.random())"
              mode="dateTime"
              :min-date="new Date()"
              timezone="UTC"
              :is-required="true"
              data-testid="end-date-select"
              :model-config="{
                type: 'string',
                mask: 'YYYY-MM-DDTHH:mm:ss',
                timeAdjust: '23:59:00',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div>
                  <p
                    class="text-sm-2 text-grey-light font-medium mb-0.4 relative"
                  >
                    Close Date
                  </p>
                  <div class="flex">
                    <input
                      :value="inputValue"
                      v-on="inputEvents"
                      class="w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
                    /><img
                      src="../../../assets/img/icons/date.svg"
                      class="relative right-4"
                    />
                  </div>
                </div>
              </template>
              <template v-slot:footer>
                <div class="absolute bottom-0 inset-x-0 h-68 opacity-60 bg-white rounded-b-lg" />
              </template>
            </DatePicker>
          </div>
          <p class="text-mdh text-grey-light py-1">{{ timeSpan }} Days</p>
        </div>

        <p class="font-bold text-mdlh text-grey-dark-1 mb-1">
          Results Permissions for Employees
        </p>
        <p class="text-mdh text-grey-dark-1 mb-3">
          Decide who can access and download results.
        </p>
        <TeamTable
          :handler="updatePermissionsList"
          :saveChangesToPermissions="saveChangesToPermissions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import moment from "moment";
import TeamTable from '../components/SettingsTable/';
import { mapState, mapActions, mapGetters } from 'vuex';
import store from '@/store';
import Button from '@/components/Button/Button.vue';
import InputField from '@/components/InputField/InputField.vue';
import arrowRight from '@/assets/img/icons/arrow-right-white.svg';
import arrowLeft from '@/assets/img/icons/arrow-right.svg';
import timezoneOptions from '../../../components/DateTimePicker/timezoneOptions';

Vue.component('date-picker', DatePicker);

export default {
  name: 'Settings',
  components: { TeamTable, Button, InputField, DatePicker },
  data: () => ({
    arrowLeft,
    arrowRight,
    range: {
      start: moment((new Date(Date.now()).setHours(6, 0, 0))).format("YYYY-MM-DDTHH:mm:ss"),
      end: moment((new Date(Date.now()).setHours(23, 59, 0))).format("YYYY-MM-DDTHH:mm:ss"),
    },
    timezone: { label: "(UTC) Coordinated Universal Time", id: "UTC" },
    permissions: null,
    timezoneOptions,
  }),
  computed: {
    ...mapState({
      title: (state) => state.assessmentSetup.assessmentTitle,
      id: (state) => state.assessmentSetup.cloneId,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
      mode: (state) => state.assessmentSetup.mode,
      subscription: (state) => state.subscriptions.subscriptionDetails,
      companyId: (state) => state.auth.decodedData["organization-id"],
      impersonatedCompanyId: (state) =>
        state.auth?.impersonateDecodedData?.["organization-id"],
    }),
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
      isSeller: "auth/isSeller",
      checkChanges: 'assessmentSetup/getDirtyGeneralInfo',
    }),
    compId() {
      return this.companyId ? this.companyId : this.impersonatedCompanyId;
    },
    productVersion(){
      return this.generalInfo.productVersion;
    },
    disabledLaunchDate() {
      return this.mode === 'edit-launched' || this.mode === 'edit-started';
    },
    dateNotAllowed() {
      return +new Date(this.range.end) > +new Date(this.subscription?.endDate);
    },
    timeSpan() {
      try {
        return (
          Math.floor(
            (Date.parse(this.range.end.slice(0, 10)) -
              Date.parse(this.range.start.slice(0, 10))) /
              60 /
              60 /
              24 /
              1000
          ) + 1
        );
      } catch (err) {
        return 0;
      }
    },
    launchDatePopover() {
      return {
        visibility: this.isSeller && (this.mode === 'edit-launched' || this.mode === 'edit-started') ? 'hidden' : 'hover-focus'
      }
    }
  },
  async mounted() {
    await this.getParticipants({ id: this.id, pageNr: 1, pageSz: 999 });
    await this.getGeneralInfo({ id: this.id });
    this.compId && await this.getSubscriptionDetails(this.compId);
    const surveyClosingTime = moment((new Date(this.generalInfo.closingTime).setHours(23, 59, 0))).format("YYYY-MM-DDTHH:mm:ss");
    if (this.mode === 'set-up') {
      if (this.generalInfo.openingTime) {
        let today = new Date(Date.now());
        today = today.toISOString().slice(0, 10);
        if (Date.parse(today) < Date.parse(this.generalInfo.openingTime)) {
          this.range.start = this.generalInfo.openingTime;
        } else this.range.start = this.range.start.toISOString();
        if (Date.parse(today) < Date.parse(surveyClosingTime)) {
          this.range.end = surveyClosingTime;
        } else this.range.end = this.range.end.toISOString();
      }
    } else {
      this.range.start = this.generalInfo.openingTime;
      this.range.end = surveyClosingTime;
    }
  },
  watch: {
    dateNotAllowed(value) {
      if (value) {
        this.openModal({ modal: 'assessmentDateModal' });
        this.range.end = this.subscription?.endDate;
      }
    },
  },
  methods: {
    ...mapActions({
      updatePermissions: 'assessmentSetup/updatePermissions',
      setSettingsCompleted: 'assessmentSetup/setSettingsCompleted',
      saveDates: 'assessmentSetup/saveDates',
      getParticipants: 'assessmentSetup/getParticipants',
      getGeneralInfo: 'assessmentSetup/getGeneralInfo',
      openModal: 'modals/openModal',
      getSubscriptionDetails: 'subscriptions/getSubscriptionDetails',
      showError: "alerts/showError",
    }),
    updatePermissionsList(val) {
      this.permissions = val;
    },
    displayVersionForSeller(version) {
      if (version &&  version !== "" && (this.isImpersonating || this.isSeller)) 
        return " (v" + version + ")";
      return "";
    },
    async goNext() {
      try {
        this.setSettingsCompleted();
        await this.saveRange();
        this.saveChangesToPermissions();
        this.$router.push('/assessments/communication');
      }
      catch(err) {
        this.showError({
          messageHeader: "Error",
          message: err.message || "Error updating settings. Please try again in a few moments",
        });
      }
    },
    async saveRange() {
      await this.saveDates({
        id: this.id,
        openingTime: this.range.start,
        closingTime: this.range.end,
        timezone: this.timezone,
      });
    },
    async saveChangesToPermissions(permissionList = []) {
      let list = []
      if(!permissionList || !permissionList.length) {
        list = this.permissions.map((item) => ({
          userId: item.id,
          permission: item.permission.value,
        }));
      } else {
        list = permissionList
      }
      try {
        await this.updatePermissions({
          id: this.id,
          permissions: list,
          surveyType: this.generalInfo?.surveyType,
        });
      } catch (err){
        this.showError({
          messageHeader: "Error",
          message: err.message || "Error updating permissions. Please try again in a few moments",
        });
      }
    },
    goBack() {
      this.$router.push('/assessments/targets');
    },
  },
  beforeRouteEnter(to, _, next) {
    const title = store.getters['assessmentSetup/getAssessmentTitle'];
    to.meta.sidenav.title = title + ' Setup';
    next();
  },
  beforeRouteLeave(to, _, next) {
    if (to.path === '/assessments/communication') next();
    else if (
      this.checkChanges({
        openingTime: this.range.start,
        closingTime: this.range.end,
      })
    )
      this.openModal({
        modal: 'exitAssessmentModal',
        props: { next: next, save: this.saveRange, path: to.path },
      });
    else next();
  },
};
</script>

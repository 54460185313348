<template>
  <div :data-testid="`listitem-${index}`">
    <div class="flex flex-row items-center h-75 w-full pl-2">
      <button class="mr-2" @click="extendToggle" data-testid="toggle-checkbox">
        <Checkbox :active="source.isSelected" />
      </button>
      <div class="flex w-tableIndex justify-center mr-5 pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/5 h-full items-center">
        <p
          class="text-md text-grey-dark-1 font-semibold"
          v-clamp="$screen.breakpoint.xlAndDown ? '20' : '25'"
        >
          {{ fullName }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/5 h-full items-center">
        <p
          class="text-md text-grey-dark-2"
          v-clamp="$screen.breakpoint.xlAndDown ? '20' : '25'"
        >
          {{ jobData }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/5 h-full items-center">
        <p
          class="text-md text-grey-dark-2"
          v-clamp="$screen.breakpoint.xlAndDown ? '20' : '25'"
        >
          {{ teams || "None" }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/5 items-center">
        <Select
          :options="options"
          :onSelect="extendSetPermission"
          :preselectFirst="false"
          :value="source.permission"
          :hasLabel="false"
          class="flex flex-grow"
          :openDirection="index <= 2 ? 'below' : ''"
          data-testid="permission-dropdown"
        />
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Select from "../../../../components/Select/NewSelect.vue";
import Checkbox from "../../../../components/Checkbox/";
export default {
  name: "ListItem",
  components: {
    Checkbox,
    Select,
  },
  data: () => ({
    options: [
      { label: "View and Download", value: 3 },
      { label: "View Only", value: 2 },
      { label: "No Access", value: 1 },
    ],
  }),
  mounted() {
    if (this.source.permission) {
      const found = this.options.find((el) => el == this.source.permission);
      this.selectedPermission = found;
    }
  },
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    toggle: { type: Function, default: () => {} },
    setPermission: { type: Function, default: () => {} },
  },
  computed: {
    ...mapState({
      generalInfo: (state) => state.assessmentSetup.generalInfo,
    }),
    fullName() {
      return this.source.firstName + " " + this.source.lastName;
    },
    jobData() {
      return this.generalInfo?.surveyType === 0
        ? this.source.jobRoleName
        : this.source.jobTitle;
    },
    teams() {
      let string = "";
      this.source.teams.map(
        (item, index) =>
          (string +=
            item.name + (index !== this.source.teams.length - 1 ? ", " : ""))
      );
      return string;
    },
  },
  methods: {
    extendSetPermission(val) {
      this.setPermission(this.source.index, val);
    },
    extendToggle() {
      this.toggle(this.index);
    },
  },
};
</script>

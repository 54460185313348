<template>
  <div>
    <div class="flex flex-grow justify-between items-center mb-2">
      <InputField
        placeholder="Name, role or team"
        label="Filter by"
        :onChange="setKeyword"
        leftIcon
        class="w-inputField"
        data-testid="filter-field"
      />
      <div class="w-1/4">
        <Select
          :options="options"
          :onSelect="setSelectedPermission"
          :preselectFirst="false"
          :value="selectedPermission"
          label="Apply to selected"
          class="flex flex-col flex-grow"
          data-testid="permission-dropdown"
        />
      </div>
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} out of {{participantsCount}} Participants
    </p>
    <div>
      <div class="flex flex-row w-full  bg-grey-light-3 py-1 pl-2 rounded">
        <button class="mr-2" @click="toggleAll">
          <Checkbox :active="selectAll" data-testid="select-all-checkbox" />
        </button>
        <div
          class="flex flex-row items-center focus:outline-none w-tableIndex mr-5"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">
            NO.
          </p>
        </div>
        <div class="flex flex-grow max-w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('name', 0)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow max-w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort(jobSorting, 1)"
            data-testid="header-2"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">{{ jobColumn }}</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow max-w-1/5">
          <div class="flex flex-row items-center focus:outline-none">
            <p class="text-sm-2 text-grey-light mr-0.4">TEAM</p>
          </div>
        </div>
        <div class="flex flex-grow max-w-1/5">
          <div class="flex flex-row items-center focus:outline-none">
            <p class="text-sm-2 text-grey-light mr-0.4">RESULTS PERMISSIONS</p>
          </div>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 370px;min-height: 222px; overflow-y: auto;"
          :data-key="'id'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          :extra-props="{ toggle: toggleItem, setPermission: setPermission }"
          data-testid="settings-table"
          @tobottom="triggerFetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { mapActions, mapState } from "vuex";
import Checkbox from "../../../../components/Checkbox/";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import InputField from "../../../../components/InputField/InputField.vue";
import Button from "../../../../components/Button/Button.vue";
import Select from "../../../../components/Select/NewSelect.vue";

export default {
  name: "TeamTable",
  components: { Checkbox, VirtualList, InputField, Button, Select },
  props: {
    handler: { type: Function },
    saveChangesToPermissions: { type: Function },
  },
  data: () => ({
    options: [
      { label: "View and Download", value: 3 },
      { label: "View Only", value: 2 },
      { label: "No Access", value: 1 },
    ],
    selectedPermission: null,
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1],
    mappedList: [],
    filteredList: [],
    selectAll: false,
    sorting: null,
    pageSz: 20,
    pageNr: 1,
    sorting: {}
  }),
  computed: {
    ...mapState({
      participants: (state) => state.assessmentSetup.participants,
      participantsCount: (state) => state.assessmentSetup.participantsCount,
      id: (state) => state.assessmentSetup.cloneId,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
    }),
    jobColumn() {
      return this.generalInfo?.surveyType === 0 ? "JOB ROLE" : "JOB TITLE";
    },
    jobSorting() {
      return this.generalInfo?.surveyType === 0 ? "jobRole" : "jobTitle";
    },
  },
  async mounted() {
    await this.updateList();
  },
  methods: {
    ...mapActions({ getParticipants: "assessmentSetup/getParticipants" }),
    setPermission(index, value, prevent) {
      this.mappedList[index].permission = value;
      if (!prevent) {
        this.saveChangesToPermissions([{userId: this.mappedList[index].id, permission: value.value}]);
      }
    },
    setSelectedPermission(val) {
      var check = false;
      this.mappedList.map((item, index) => {
        if (item.isSelected) {
          this.setPermission(index, val, true);
          check = true;
        }
      });
      if (check) this.saveChangesToPermissions(
        this.mappedList.filter(x => x.isSelected).map(x => ({userId: x.id, permission: x.permission.value}))
      );
    },
    async updateList(reset) { 
      if(reset) {
        this.pageNr = 1;
        this.mappedList = []
        if(!this.selectAll) {
          this.pageSz = 20
        }
      }
      await this.getParticipants({
        id: this.id,
        pageNr: this.pageNr,
        pageSz: this.pageSz,
        keyword: this.keyword,
        sorting: this.sorting,
      });
      let map = this.participants.map((item, idx) => ({
        ...item,
        index: idx,
        isSelected: this.selectAll,
        permission: null,
      }));
      this.mappedList = [...this.mappedList, ...map];
      this.mappedList.map((item, index) => {
        const value = this.findPermission(item.resultPermission);
        this.setPermission(
          index,
          value ? value : { label: "View and Download", value: 3 },
          true
        );
      });
    },
    sort(key, index) {
      this.setRotation(index);
      switch (key) {
        case "name":
         this.sorting = {firstName: this.rotationMatrix[index] === 1 ? 1 : 2}
          break;
        case "jobRole":
        this.sorting = {firstRole: this.rotationMatrix[index] === 1 ? 1 : 2}
          break;
        case "jobTitle":
        this.sorting = {firstTitle: this.rotationMatrix[index] === 1 ? 1 : 2}
          break;
        default:
          break;
      }
      this.updateList(true)
    },
    findPermission(value) {
      const found = this.options.filter((item) => item.value == value);
      return found ? found[0] : this.options[0];
    },
    setRotation(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx == index ? -1 * item : 1
      );
    },
    setKeyword: debounce(function (value) {
      this.keyword = value;
    }, 500),
    toggleItem(index) {
      this.mappedList[index].isSelected = !this.mappedList[index].isSelected;
    },
    async toggleAll() {
      this.selectAll = !this.selectAll;
      if(this.selectAll) {
        this.pageSz = this.participantsCount;
        await this.updateList(true);
      }
      this.mappedList.map((item) => (item.isSelected = this.selectAll));
    },
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.participantsCount / this.pageSz)) {
        this.pageNr++;
        this.updateList();
      }
    },
  },
  watch: {
    mappedList(value) {
      this.handler(value);
    },
    keyword() {
      this.updateList(true);
    },
  },
};
</script>
